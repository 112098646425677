@import './assets/dist/css/bootstrap.min.css';
@import url('https://use.typekit.net/bta0ncf.css');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css');

@media (max-width: 767px) {
    .hideOnMobile {
        display: none !important;
    }
}

@media (min-width: 768px) {
    .hideOnDesktop {
        display: none !important;
    }
}

button.dropdown-toggle.btn.btn-fav::after {
    display: none;
}

.btn:focus,
.btn:active {
    outline: none !important;
    box-shadow: none;
}

h6 {
    font-weight: 400 !important;
    color: var(--text-base) !important;
}

/* custom button styles */

.btn-normal {
    color: rgba(255, 255, 255, 0.83);
    line-height: 1.3;
    font-weight: 100 !important;
    background: rgba(7, 9, 13, 0.2);
    border-color: #dadcdf;
    /* border-top-width: 1px;
  border-bottom-width: 1px;
  border-left-width: 1px;
  border-right-width: 1px; */
    border: none;
    border-radius: 6px;
}

.btn-normal:hover {
    background-color: rgba(7, 9, 13, 0.4) !important;
    /* color: rgba(255,255,255,0.83); */
    box-shadow: none !important;
    color: #65d0a2;
}

.btn-mnav:active {
    color: #65d0a2;
}

.btn-transparent {
    color: rgba(255, 255, 255, 0.83);
    line-height: 1.3;
    font-weight: 100 !important;
    /* background: rgba(108, 114, 127, 0.24); */
    border-color: #dadcdf;
    /* border-top-width: 1px;
  border-bottom-width: 1px;
  border-left-width: 1px;
  border-right-width: 1px; */
    border: none;
    border-radius: 6px;
}

.btn-transparent:hover {
    background-color: #dadcdf !important;
}

/* end of button styles */

.whiteheading {
    font-family: 'automate';
    color: white !important;
    opacity: 0.9;
}

.table-hover tbody tr:hover td,
.table-hover tbody tr:hover th {
    background-color: var(--background-light);
}

hr {
    color: #bfbfbf;
}

.normal-link {
    color: #48c193;
    text-decoration: none;
}

.normal-link:hover {
    color: var(--text-green);
    text-decoration: none;
}

body {
    background-color: var(--background-base);
    font-family: rubik, sans-serif;
    color: var(--text-base);
    font-size: 12px;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

body::-webkit-scrollbar {
    display: none;
}

.emblem {
    padding: 3px;
    background-color: #292d40;
    border-radius: 100px;
    margin-right: 5px;
}

.smalltext {
    font-weight: 400;
    font-size: 11px;
    opacity: 0.87;
}

.btn-secondary[href]:active,
.btn-secondary[href]:focus,
.btn-secondary[href]:hover,
.btn-secondary[type]:active,
.btn-secondary[type]:focus,
.btn-secondary[type]:hover {
    box-shadow: none !important;
}

/* Header.js */

/* mobile */
.connected-button-mobile {
    width: 100%;
}

/* end of mobile */

.brand {
    position: relative !important;
    padding-top: 0 !important;
}

.brand::after {
    content: '';
    height: 30%;
    width: 1px !important;
    position: absolute;
    right: 0;
    top: 38%;
    background-color: var(--color-border-grey) !important;
}

#dropdown-basic:hover {
    background-color: var(--background-darker) !important;
}

#dropdown-basic {
    border: none;
    border-radius: 8px;
    font-size: 14px;
    color: var(--text-muted);
    background-color: var(--background-base) !important;
}

#dropdown-basic::after {
    display: none;
}

.psmall {
    font-size: 13px;
    margin-left: 5px;
}

.bg-nav {
    border-bottom: 1px solid var(--color-border-grey);
    height: 48px;
    margin-bottom: 0 !important;
}

.wdropdown-btn {
    border-radius: 0 8px 8px 0 !important;
    color: #898989;
    height: 32px;
    margin-top: 0px !important;
    font-size: 16px !important;
    margin-left: -10px !important;
    background-color: var(--background-lighter);
}

.wdropdown-btn:hover {
    color: var(--text-primary);
}

.newnoti {
    padding: 5px;
    margin-top: 3px;
    border: 1px solid #292d40 !important;
}

.cog-btn {
    background-color: transparent;
    margin-top: -4px;
    height: 49px;
    height: 35px;
    margin-right: 5px;
}
.wallet-btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 7px;
    width: 150px;
    cursor: pointer !important;
}

.wbalance {
    color: var(--text-base);
    padding-right: 0px !important;
    padding-left: 7px !important;
    display: inherit;
    font-weight: 400;
    font-size: 12px;
}

.pokeprice {
    line-height: 2;
    padding-right: 10px;
    color: var(--text-green);
}

.wicon {
    margin-top: -2px !important;
}

#ninjabutton {
    background-color: transparent;
    border: 0;
}

button.wallet-btn.btn.btn-mobile-main {
    height: 32px !important;
}

/* Navigationdata.js */

.nav-report {
    margin-top: 5px;
}

/* Updated Crypto.js styling */

.infodivider {
    position: relative !important;
    padding-top: 0 !important;
}

.infodivider::after {
    content: '';
    height: 50%;
    width: 1px !important;
    position: absolute;
    right: 0;
    top: 26%;
    background-color: var(--color-border-grey) !important;
}

.smalldivider {
    position: relative !important;
    padding-top: 0 !important;
}

.smalldivider::after {
    content: '';
    height: 62%;
    width: 1px !important;
    position: absolute;
    right: 0;
    top: 21%;
    background-color: var(--color-border-grey) !important;
}

div.mb-3.dexwidth.container {
    padding-left: 0 !important;

    /* margin-top: 0.3%; */
}

#button-tooltip-2 {
    font-size: 9px;
}

.badge-link {
    background-color: #2d3448 !important;
    padding: 4.2px;
}

.badge-second {
    background-color: #161c29;
    padding: 4.2px;
}

.badgehover:hover {
    background-color: var(--background-dark) !important;
    cursor: pointer;
}

.lightbadge {
    background-color: var(--background-base) !important;
    cursor: pointer;
}

.lightbadge:hover {
    background-color: var(--background-dark) !important;
    cursor: pointer;
}

.bg-darkgrey {
    background-color: var(--background-lighter) !important;
}

.bg-red {
    background-color: var(--text-lightred);
}

.bg-orange {
    background-color: var(--color-lightorange);
}

.bg-green {
    background-color: var(--text-lightgreen2);
}

.btn-reload {
    font-size: 10px;
    height: 20px;
    line-height: 0.5;
    margin-right: 1%;
    border: 1px solid var(--text-green) !important;
    color: white;
    cursor: pointer !important;
}

.btn-reload:hover {
    cursor: pointer !important;
    color: white;
}

.linkbadge {
    font-size: 11px;
    font-weight: normal;
}

.cardvalue {
    font-size: 15px;
    color: var(--text-base);
    font-weight: 100;
    font-family: monotalic;
    top: 2% !important;
    position: relative;
}

.cardname {
    font-size: 12.5px;
    font-family: 'Ligurino';
    color: var(--text-muted);
    font-weight: 100;
}

.pairdropdown {
    /* margin-left: 6px; */
    margin-right: -45px;
    /* margin-bottom: 5px; */
    background: transparent;
    border: none !important;
    /* width:100%!important; */
    /* width:235px; */
    box-shadow: none !important;
    outline: none !important;
    -webkit-appearance: none;
    appearance: none !important;
}

.pairdropdown :hover {
    box-shadow: none !important;
    border: none !important;
    background-color: var(--background-base) !important;
    outline: none !important;
    -webkit-appearance: none !important;
    appearance: none !important;
    outline: none !important;
}

.pairdropdown:focus {
    box-shadow: none !important;
    outline: none !important;
    -webkit-appearance: none !important;
    appearance: none !important;
    outline: none !important;
}

#pairdropdowntoggle {
    border: none;
    height: 26px;
    /* border-radius: 8px; */
    background-color: transparent !important;
}

#pairdropdowntoggle:hover {
    background: var(--background-base) !important;
}

@media only screen and (max-width: 798px) {
    #pairdropdowntoggle {
        height: 5.5em;
    }
    #pairdropdowntoggle:hover {
        background-color: transparent !important;
    }

    .pairdropdown {
        /* margin-left: 1%;
    margin-right: -20px !important;
    margin-bottom: 3%;
    margin-top: 4px; */
        width: 100vh;
    }
}

.keydatawrapper::-webkit-scrollbar {
    display: none !important;
}

.keydatawrapper {
    overflow-y: hidden !important;
}

@media only screen and (max-width: 600px) {
    .keydatawrapper {
        margin-top: 1%;
        margin-left: 0.5em !important;
        padding-left: 4px !important;
        overflow: hidden;
        overflow-x: scroll !important;
        position: relative;
        margin-right: -44px !important;
        -ms-overflow-style: none;
        /* IE and Edge */
        scrollbar-width: none;
        /* Firefox */
    }

    .keydatawrapper::-webkit-scrollbar {
        display: none !important;
    }
}

@media only screen and (max-width: 600px) {
    .keydatarow {
        margin-top: 0px;
    }

    fade {
        position: absolute;
        right: 0;
        display: block;
        width: 13%;
        height: 90%;
        margin-right: -10px !important;
        background-image: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(35, 35, 51, 1) 100%);
        flex-direction: row;
    }
}

.scrolling-wrapper-keydata {
    display: flex;
    height: 3.6em;
    /* width: 100%; */
    /* padding: 0 1em; */

    overflow-x: auto;
    overflow-y: hidden;
    -webkit-overflow-scrolling: grab;
    /* margin-left: -0.94em !important; */
}

.keydatacard {
    position: relative !important;
    flex: 0 0 auto;
    /* height: 3em; */
    width: auto;
    padding: 0px 20px 0px 20px;
    box-shadow: none;
    -webkit-box-shadow: none;
    /* border-right: 1px solid var(--color-border-grey); */
}

.keydatacard::after {
    content: '';
    height: 75%;
    width: 1px !important;
    position: absolute;
    right: 0;
    top: 14%;
    background-color: var(--color-border-grey) !important;
}

.form-group {
    margin-bottom: 0px !important;
    width: 100%;
}

.trendingbar {
    /* margin-top: -20px; */
    /* margin-bottom: 10px !important; */
    height: 36px;
    /* margin-left: 2px; */
    /* margin-right: 5px; */
}

.searchbar-button:hover {
    filter: brightness(115%) !important;
}

/* DEXPAGE styling */

.dexwidth {
    max-width: calc(100% - 11px);
    width: 100vw;
    background-color: var(--background-base);
    /* margin-top: 4px; */
    /* margin-left: 6px;
    margin-right: 7px; */
    border-radius: 7px;
}

div#pills-tabContent.tab-content.rounded {
    width: 100% !important;
}

.swap-limit {
    width: 100%;
}

/*********************
* Modal Select Token * 
*********************/

div.list-group-item {
    background-color: transparent;
    color: #c3c2d4;
    display: flex;
    justify-content: space-between;
    font-size: 13px !important;
}

.searchicon-token-bar {
    top: 5px !important;
    left: 19px;
    position: relative;
    z-index: 999;
    color: var(--text-base) !important;
    color: #c3c2d4 !important;
}

.dex-search-bar {
    padding: 0px 10px 0px 40px !important;
}

.dex-search-bar::placeholder {
}

@media (max-width: 767px) {
    .dex-search-bar {
        padding: 0px 10px 0px 46px !important;
    }
    input.dex-search-bar.form-control {
        height: 34px !important;
        margin-top: -18px !important;
        margin-left: -20px;
    }
    .trendingbar {
        height: 43px;
        margin-top: -3px;
    }
    .searchicon-token-bar {
        top: 5px !important;
        left: 8px;
        position: relative;
        z-index: 9999;
        color: var(--text-base) !important;
    }
}

.dex-search-bar-mobile ::placeholder {
    color: #000 !important;
}

@media (max-width: 767px) {
    .dex-search-bar-mobile {
        padding: 0px 10px 0px 46px !important;
        margin-left: -10px !important;
        border-radius: 0;
        width: 110% !important;
    }
    input.dex-search-bar-mobile.form-control {
        height: 42px !important;
        margin-top: -18px !important;
        margin-left: -10px;
        /* border-bottom: 1px solid var(--color-border-grey) !important; */
        margin-bottom: 5px;
    }
    .trendingbar {
        height: 43px;
        margin-top: -3px;
    }
    .searchicon-token-bar {
        top: 8px !important;
        left: 20px;
        position: relative;
        z-index: 9999;
        color: var(--text-base) !important;
        margin-left: -10px;
    }
}

input.dex-search-bar.form-control {
    background-color: #303044 !important;
    color: #c3c2d4 !important;
    border-radius: 7px !important;
}

/* input.dex-search-bar.form-control:focus {
  -webkit-box-shadow: 0px 0px 25px -10px rgba(0, 0, 0, 0.55);
  -moz-box-shadow: 0px 0px 25px -10px rgba(0, 0, 0, 0.55);
  box-shadow: 0px 0px 25px -10px rgba(0, 0, 0, 0.55);
} */

/* crypto.js */

button.cbuy.d-flex.p-0.tprice.btn.btn-pricebutton:hover {
    color: var(--text-green) !important;
    background: rgba(7, 9, 13, 0.4) !important;
    border-radius: 8px !important;
    outline: none !important;
}

button.dropdown-toggle.btn.btn-info-dropdown::after {
    margin-left: 8px;
    font-size: 11px;
    margin-top: 1px;
}

.action-row {
    margin-right: -24px !important;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
    /* border-bottom: 1px solid #2d2d3c; */
}

.tokendropdown-toggle:hover {
    color: #fff;
}

/* .gainloss {
  width: 13px !important; 
  margin-right: 6px;
  margin-top: -2px;
} */

.tokenstats {
    margin-top: -4px;
}

/* .tokenstats-left {
  margin-top: -6px;
  width: 16.5%;
  margin-left: 0;
  border: 2px solid #253138;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px,
    rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  margin-right: 5px;
  border-radius: 10px;
  padding-top: 7px;
} */

@media only screen and (max-width: 600px) {
    .lp-dropdown {
        margin-top: 10px;
        margin-right: 9px;
    }
}

.lp-dropdown-listitem-icon {
    height: 20px;
    width: 20px;
    margin-right: 10px;
    vertical-align: middle;
}

.lp-dropdown-listitem-text {
    font-weight: 400;
    font-size: 12px;
    /* font-family: automate, monospace; */
    vertical-align: middle;
    /* line-height: 2em; */
}

.lp-dropdown-list {
    position: absolute;
    inset: 0 auto auto 0;
    margin: 0;
}

.cryptoname {
    font-size: 15px;
    color: var(--text-base);
    font-weight: 500;
    margin-left: -15px;
    /* line-height:1; */
    font-family: 'Ligurino';
}

.cryptoname-mobile {
    font-size: 13px;
    color: var(--text-muted);
    margin-left: -18px;
    font-weight: 100;
    text-transform: capitalize;
    /* font-family: 'Ligurino'; */
}

.tprice {
    font-size: 15px;
    /* margin-top: -9px; */
    font-weight: 400;
    margin-top: -4px !important;
    padding-left: 4px !important;
    padding-right: 4px !important;
}

/* .lpname {
  font-size: 13px;
  color: #bfbfbf;
  vertical-align: middle;
} */

/* #tokenlogo {
  padding-left: 8px;
  padding-right: 0;
  margin-top: -1px;
  clip-path: rect(10px, 20px, 30px, 40px);
} */

/* #tokenlogo img {
  width: 30px;
  height: 30px;
} */

/* #tokenprice,
#tokenmain {
  margin-right: 0.5%;
  margin-top: 2px;
  font-size: 11px;
  font-weight: 500;
  color: #fefefc;
  line-height: 1.3;
} */

/* #lppair {
  margin-top: 2px;
  padding: 2px;
}

#txtSearchInput {
  position: relative;
  display: block;
  padding: 5px 10px 5px 44px;
} */

::placeholder {
    color: var(--text-base) !important;
}

/* .searchicon {
  position: relative;
  z-index: 9999;
  left: 33px;
  top: 10px;
  font-size: 17px;
  color: white;
  height: 100%;
} */

.dex-search form-control {
    color: var(--text-muted) !important;
}

/* .searchicon-token {
  position: relative;
  z-index: 9999;
  left: 20px;
  top: 11px;
  font-size: 17px;
  color: #6c727f !important;
  height: 100%;
}

.searchicon-token-bar {
  top: 13px;
  left: 20px;
  position: relative;
  z-index: 9999;
  color: #6c727f !important;
}

#tradeswitchheader {
  font-weight: 600;
  font-size: 11px;
  padding-bottom: 4px;
}

#traderow {
  margin-top: -1px;
}

.cstats {
  font-family: "automate";
  font-weight: 400;
  font-size: 14px;
}

.rightside {
  margin-top: -25px;
  flex-direction: row;
  flex-wrap: wrap;
} */

/* TV chart button */

/* .charttoggle {
  width: 35px;
  opacity: 0.9;
  margin-left: 20px;
  padding: 2px;
  cursor: pointer;
  margin-top: -21px;
}

.charttoggle:hover {
  padding: 1px;
}

.charttoggle:active {
  opacity: 0.8;
  padding: 1px;
}
.charttoggle-active {
  color: var(--text-green);
  opacity: 1;
}

.tokeninfomodal {
  z-index: 9999;
}

.infoTable {
  background-color: transparent !important;
} */

/* End of Crypto.js CSS */

/* svg.mr-2.mb-1.linkbadge {
  margin-top: -24%;
  margin-left: 7%;
  margin-right: 15px;
} */

.list-inline2 {
    padding-left: 0px;
    list-style: none;
    display: inherit;
    margin-bottom: 0px;
}

.form-control {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 1rem;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.5;
    color: var(--text-primary);
    /* border: 1px solid #161c29; */
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

/* The slider */
/* .slider {
  position: absolute;
  cursor: pointer;
  margin-left: 9px;
  height: 18px;
  width: 37px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.slider-trade {
  background-color: #7f7f7f;
} */

.slider:before {
    position: absolute;
    content: '';
    height: 12px;
    width: 12px;
    left: 4px;
    bottom: 3px;
    background-color: var(--background-dark);
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

input:checked + .slider {
    background-color: var(--text-green);
}

input:focus + .slider {
    box-shadow: 0 0 1px var(--text-green);
}

input:checked + .slider:before {
    -webkit-transform: translateX(18px);
    -ms-transform: translateX(18px);
    transform: translateX(18px);
}

.nobdr {
    border: 0px;
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

.bg-darker {
    /* background-color: #0e121b; */
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
    border-radius: 5px !important;
}

.ml50 {
    margin-left: 50px;
}

.pt-8 {
    padding-top: 8px;
    padding-bottom: 8px;
}

.price {
    padding-left: 5px;
    padding-right: 50px;
}

.nplr {
    padding-left: 15px;
    padding-right: 15px;
}

/* .grey {
  color: #000;
  font-weight: 500;
}

.grey-tx {
  color: #000;
  font-weight: 400;
  font-size: 11px;
  font-family: "monotalic", sans-serif;
} */

.micn {
    width: 15px;
    height: 15px;
    margin-right: 10px;
}

.micn-span {
    display: flex;
    height: 32px !important;
    align-items: center;
    margin-top: 2px;
}

.mpr {
    margin-top: 11px;
    padding-left: 5px;
    padding-right: 25px;
}

.f12 {
    font-size: 12px;
}

.cbuy {
    color: var(--text-green);
    padding-right: 5px;
    font-family: 'monotalic';
    font-weight: 100;
}

.cbuy a {
    color: var(--text-green);
    font-weight: 100;
}

.tcbuy {
    color: var(--text-green);
    font-family: 'monotalic';
    font-weight: 100;
}

.tcbuy a {
    color: var(--text-green);
    text-decoration: none;
    font-weight: 100;
}

.tcsell {
    color: var(--text-red);
    font-family: 'monotalic';
    font-weight: 100 !important;
}

.tcsell a {
    color: var(--text-red);
    text-decoration: none;
}

.csell {
    color: var(--text-red);
    font-family: 'monotalic';
    font-weight: 100;
}

.csell a {
    color: var(--text-red);
    font-weight: 100;
}

.tbg {
    background: #1d1d1d;
    padding: 10px;
}

.bd-placeholder-img {
    font-size: 1.125rem;
    text-anchor: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}

.has-search .form-control {
    /* padding-left: 0.375rem; */
    background: #13121e;
    border-radius: 10px !important;
    border: 1px solid #6c727f80;
    /* border-image-slice:1;
  border-image-source: linear-gradient(to left, #743ad5, #d53a9d); */
    font-size: 12px;
    color: #6c727f;
}

.has-search .form-control-feedback {
    position: absolute;
    z-index: 2;
    display: block;
    width: 2.375rem;
    height: 2.375rem;
    line-height: 2.375rem;
    text-align: center;
    pointer-events: none;
    color: #aaa;
}

select {
    -webkit-appearance: none;
    appearance: none !important;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
}

.input_amount {
    background: transparent;
    border: none;
    font-size: 1.5em;
    color: #fff;
}

.slippage {
    background: transparent;
    border: none;
    width: 2em;
    color: white;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    appearance: none !important;
    margin: 0;
}

/* Firefox */
input[type='number'] {
    -moz-appearance: textfield;
    appearance: none !important;
}

.tbnb {
    font-size: 18px;
    font-weight: 400;
    color: #89909f;
    margin-left: 10px;
    background: transparent;
    border: none;
}

.abx {
    padding: 6px 15px;
    margin-left: 5%;
    background: #1a202e;
    border-radius: 6px;
    text-align: center;
}

.abx2 {
    padding: 6px 10px;
    margin-left: 5%;
    background: #1a202e;
    border-radius: 6px;
    text-align: center;
}

.tp-head {
    background: var(--background-dark);
    backdrop-filter: blur(3px);
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    color: #6f6e84;
    box-shadow: inset 0 -1px 0 var(--color-border-grey);
    font-weight: 400 !important;
    padding: 13px;
    /* opacity: 87%; */
}

.greenn {
    color: #6e9f4d;
}

.table thead th {
    vertical-align: bottom;
    border-bottom: 0px solid #dee2e600;
}

.table-sm td,
.table-sm th {
    padding: 0.5rem;
    border-bottom: 0px;
}

.table td,
.table th {
    padding: 0.5rem;
    vertical-align: top;
    border-top: none;
}

.tund {
    text-decoration: underline;
    font-size: 14px;
    margin-left: 10px;
}

.b-skills {
    border-top: 1px solid #f9f9f9;
    padding-top: 46px;
    text-align: center;
}

.b-skills:last-child {
    margin-bottom: -30px;
}

.b-skills h2 {
    margin-bottom: 50px;
    font-weight: 900;
    text-transform: uppercase;
}

.skill-item {
    position: relative;
    max-width: 250px;
    width: 100%;
    margin-bottom: 30px;
    color: #555;
}

/* .chart-container {
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 100%;
  margin-bottom: 27px;
} */

.skill-item .chart,
.skill-item .chart canvas {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
}

.skill-item .chart:before {
    content: '';
    width: 0;
    height: 100%;
}

.skill-item .chart:before,
.skill-item .percent {
    display: inline-block;
    vertical-align: middle;
}

.skill-item .percent {
    position: relative;
    line-height: 1;
    font-size: 40px;
    font-weight: 900;
    z-index: 2;
}

.product-info span a {
    color: #fff;
    text-decoration: none;
    cursor: pointer;
}

.skill-item .percent:after {
    content: attr(data-after);
    font-size: 20px;
}

.info-tab a {
    cursor: pointer;
}

a.infolink {
    color: var(--text-green);
}

a.link {
    text-decoration: none;
    color: #959cb2;
}

/****************************************************
* 🐸 Filename: WalletTransactions.js
****************************************************/

.position-info-container {
    background-color: var(--background-dark);
    border-top: 1px solid var(--color-border-grey);
}

.position-info-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    background-image: linear-gradient(342.62deg, rgb(35, 34, 46) -9.23%, rgb(48, 48, 71) 110.36%);
    height: 42px;
    background-color: var(--background-base);
}

/* td-class targets each */
.td-class {
    color: var(--text-muted);
}

/* Wallet CSS styting */

.walletsidebar {
    color: white;
    z-index: 99999;
    padding: 10px;
}

.offcanvas {
    /* background-color: var(--background-light)!important; */
    background-color: var(--background-base);
    border: none;
    backdrop-filter: blur(100%) !important;
}

.bigbalance {
    color: var(--text-green);
    font-size: 42px;
    font-weight: 500;
    font-family: 'monotalic';
    margin-bottom: 0px;
    margin-top: -10px;
}

.w-tokenval {
    text-align: right;
    background-color: var(--background-base) !important;
    /* border-bottom: 1px solid #2d3a4e !important; */
    border-top: 1px solid #2d3a4e !important;
}

.w-connect {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
}

.w-widget {
    background-color: var(--background-base) !important;
}

.w-head {
    background-color: var(--background-base);
}

span.w-text {
    color: var(--text-base);
}
.w-text {
    font-size: 12px;
}

.w-tokenname {
    text-align: left;
    background-color: var(--background-base) !important;
}

.w-tokenname a {
    color: #ffffff;
}

.table-striped > tbody > tr:nth-child(2n + 1) > td,
.table-striped > tbody > tr:nth-child(2n + 1) > th {
    background-color: #111019;
    border-bottom: 1px solid #202332;
}

.table-striped > tbody > tr:nth-child(even) > td,
.table-striped > tbody > tr:nth-child(even) > th {
    background-color: #0f0e15;
    border-bottom: 1px solid #202332;
}

/* Transaction feed CSS */

/* div.bodyscroll {
    overflow: auto !important;
    height: 100px;
} */

/* CryptoOverview.js CSS */
.meep {
    border-radius: 7px !important;
}

/* PriceCalculator.js */

@media only screen and (max-width: 599px) {
    .priceCalcMobile {
        margin-left: 0 !important;
        margin-top: 3.4% !important;
    }

    .tFeed {
        margin-left: 0 !important;
        margin-top: 3.4% !important;
    }
}

.converterbox {
    background-color: var(--background-light) !important;
    border-radius: 10px;
}

.cinput {
    width: 100%;
    height: 30px;
    /* background: #151522; */
    background: var(--background-lighter);
    color: var(--text-base);
    border: 0px solid #292d40;
    border-radius: 9px;
    padding-left: 10px;
    font-family: 'monotalic';
}

/* TokenInfo.js CSS styling */

.swapsoon {
    border-radius: 10px;
    border: 1px solid #2a2d3f;
    padding: 10px;
    /* height:100px; */
}

.swapcomponent {
    background-color: #151522 !important;
    border-radius: 10px !important;
}

.fromtoken {
    background-color: #292d40;
    border-radius: 8px;
}

.swapinput2 {
    background-color: #292d40 !important;
    border: none !important;
}

#input-group-dropdown-2 {
    color: var(--text-base) !important;
    background-color: var(--background-lighter);
    height: 100%;
    border-radius: 7px !important;
}

.alert-borderline {
    border: 1px solid rgba(242, 143, 59, 0.3);
    color: #bfbfbe;
}

div.col-2.mt-0.p-0.bg-darker-trade {
    padding: 0;
    overflow: hidden;
    width: 300px !important;
    max-width: 300px !important;
    min-width: 300px !important;
    position: relative;
    background-color: var(--color-border-grey);
    z-index: 7;
}

div.col-8.pl-0.pr-0.graph {
    width: calc(100% - 600px);
    /* height: 100% !important; */
}

.nav-pills .nav-item .pill-1:not(.active) {
    background-color: transparent;
    color: grey;
}

.nav-pills .nav-item .pill-1 {
    background-color: var(--background-dark);
    border-radius: 0px 0px 0px 0px !important;
    /* border-bottom: 1px solid var(--color-border-grey); */
}

.nav-pills .nav-item .pill-2:not(.active) {
    background-color: var(--background-base);
    color: grey;
}

.nav-pills .nav-item .pill-2 {
    background-color: var(--background-light);
    border-radius: 0px 0px 0px 0px !important;
    border-left: 1px solid var(--color-border-grey);
}

.swapbox {
    background-color: #171927;
    border: 2px solid #253138;
    border-radius: 7px;
}

.swapinput {
    background: #151522;
}

.swaptext {
    color: #bfbfbe;
}

/* Bootstrap styling */

.btn-borderline {
    background-color: var(--color-teal);
    border-radius: 12px;
    height: 37px;
    font-weight: 100;
    color: var(--text-primary) !important;
    width: 123px;
}

.btn-borderline:hover {
    filter: brightness(122%);
}

.display-6 {
    font-size: 16px;
    font-weight: 400;
    opacity: 0.83 !important;
}

/* 
   table for the walletsidebar, dealing with this later
*/

.table-dark {
    --bs-table-bg: #100d16;
    --bs-table-striped-bg: #2c3034;
    --bs-table-striped-color: #fff;
    --bs-table-active-bg: #373b3e;
    --bs-table-active-color: #fff;
    --bs-table-hover-bg: #151522;
    --bs-table-hover-color: #fff;
    color: #fff;
}

.table td {
    border-bottom: 1px solid var(--background-light) !important;
}

.btn-secondary {
    background-color: var(--background-lighter) !important;
}

.dropdown-menu-dark {
    margin-top: -2px !important;
    background-color: var(--background-light) !important;
    box-shadow: none;
    border-radius: 0px;
    border: 1px solid var(--color-border-grey);
    width: 100%;
}

.dropdown-menu-dark:hover {
    border: 1px solid var(--color-border-grey) !important;
    background-color: var(--background-light) !important;
}

@media screen and (min-width: 1000px) {
    .dropdown-menu-dark {
        top: 24px !important;
    }
    .lp-dropdown-ul {
        margin-left: -66px !important;
        min-width: 172px;
    }
}

.dropdown-menu-dark.dropdown-item:hover {
    color: var(--text-base);
    background-color: var(--background-light) !important;
}

.form-control {
    border: none !important;
    background-color: var(--background-light) !important;
}

input.amount-in-out.form-control:focus {
    background-color: transparent !important;
}

input.amount-in-out.form-control {
    color: var(--text-muted) !important;
    background-color: var(--background-light) !important;
}

input.shadow-none.form-control:focus {
    background-color: transparent !important;
}

/* div.biginput-shadow.container {
  box-shadow: 0px 5px 18px 2px rgba(21, 23, 33, 0.15);
  -webkit-box-shadow: 0px 5px 18px 2px rgba(21, 23, 33, 0.15);
  -moz-box-shadow: 0px 5px 18px 2px rgba(21, 23, 33, 0.15);
} */

.dropdown-toggle {
    font-size: 12px;
}

.pairdropdown .dropdown-toggle::after {
    margin-top: 1px;
    color: var(--text-muted);
    border-top: 0.5em solid;
    border-right: 0.42em solid transparent;
    border-bottom: 0;
    border-left: 0.42em solid transparent;
    size: 30px;
    margin-left: 10px;
}

.dropdown-toggle::after {
    margin-left: 7px;
}

@media screen and (max-width: 900px) {
    .dropdown-toggle::after {
        margin-top: 15px !important;
    }
}

.bg-secondary {
    color: var(--text-muted) !important;
    background-color: var(--background-lighter) !important;
    /* border: none !important; */
}

.bg-dark {
    background-color: transparent !important;
    box-shadow: none;
}

.bg-dark-bordered {
    background-color: transparent !important;
    border: 1px solid var(--color-border-grey);
    box-shadow: none;
}

/* .bg-darkish {
  background-color: blue !important;
  border: 1px solid #2d3a4e;
  box-shadow: none;
} */

.border-secondary {
    border-color: #2d3a4e !important;
}

.border-secondary-alt {
    /* border-top: 1px solid #2d3a4e!important; */
    /* border-bottom: 1px solid #2d3a4e!important; */
    border-left: 1px solid var(--color-border-grey) !important;
}

/* Home.js CSS */

/* ... go over everything below */

@media only screen and (max-width: 899px) {
    .home-txt {
        width: 350px !important;
        overflow: hidden !important;
    }
}

@media only screen and (max-width: 599px) {
    .sentimentCard {
        width: 90% !important;
        margin: 20px 20px 0px 20px;
    }
}

@media only screen and (min-width: 600px) {
    .sentimentCard {
        margin: 20px 20px 20px 20px;
    }
}

.sentimentTitle {
    color: white !important;
}

.sentimentSubtitle {
    font-family: rubik;
    font-size: 25px;
    font-weight: 100;
}

.text-muted {
    color: var(--text-muted) !important;
}

.text-base {
    color: var(--text-base) !important;
}

.text-primary {
    color: var(--text-primary) !important;
}

.tokenomicspage {
    width: auto;
    max-width: 100%;
}

@media only screen and (max-width: 899px) {
    .tokenomicspage {
        width: auto;
        max-width: 100%;
    }
}

@media only screen and (max-width: 1200px) {
    .homelists {
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
    }

    .homelist1 {
        margin-bottom: 14px;
    }

    .homelist2 {
        margin-bottom: 14px;
    }
}

@media only screen and (min-width: 1201px) {
    .homelists {
        display: flex;
        flex-direction: row;
        margin-bottom: 7em;
    }

    .homelist1 {
        margin-right: 7px;
    }

    .homelist2 {
        margin-left: 7px;
    }
}

.scrolling-wrapper-intro {
    display: flex;
    height: 24em;
    width: 100%;
    padding: 3em 1em;
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
}

.scrolling-wrapper-intro::-webkit-scrollbar-track {
    -webkit-box-shadow: none;
    box-shadow: none;
    border-radius: 10px;
    background-color: transparent;
}

.scrolling-wrapper-intro::-webkit-scrollbar {
    width: 12px;
    background-color: transparent;
}

.scrolling-wrapper-intro::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: none;
    box-shadow: none;
    background-color: #131824;
}

/* Nav-text CSS */

.nav-text {
    display: flex;
    padding: 0px 4px 0px 4px;
    list-style: none;
}

.nav-text a {
    text-decoration: none;
    padding: 10px 10px 10px 0px;
    color: var(--text-muted) !important;
    font-size: 14px;
    font-family: 'Ligurino';
    height: 100%;
    line-height: 0.9;
    border-radius: 9px;
    letter-spacing: 0.3px;
    padding-left: 14px !important;
    padding-right: 14px !important;
}

.nav-text a:hover {
    color: var(--text-base) !important;
    -webkit-transition: color 0.5s;
    transition: color 0.5s;
    background-color: var(--background-lighter);
    border-radius: 9px;
}

.nav-text a:active {
    color: white;
    -webkit-transition: color 0.5s;
    transition: color 0.5s;
}

.navbar-dark .navbar-nav .nav-link.active {
    background-color: var(--background-darker);
    border-radius: 8px;
    -webkit-transition: color 1s;
    transition: color 0.5s;
}

/* .navtitle {
  margin-left:10px;
  margin-bottom:5px;
} */

/** home auto search **/

/* .home-txt {
  align-self: center !important;
  background: #13121e;
  border-radius: 10px;
  font-size: 18px;
  font-weight: 300;
  color: #6c727f !important;
  width: 920px;
  padding: 5px 10px 5px 40px !important;
  height: calc(1.5em + 1.2rem + 2px);
} */

/* .dex-search {
  background: #292d4099 !important;
  font-size: 18px;
  color: #000;
  border: none !important;
  padding: 5px 10px 5px 40px !important;
  height: calc(1.1em + 1.2rem + 2px);
  margin-top: -9px;
} */

@media (max-width: 767px) {
    .dex-search {
        margin-top: -12px;
    }

    .searchicon-token {
        top: 9px;
    }
}

.searchicon-home {
    position: relative;
    z-index: 9999;
    left: 22px;
    top: 29px;
    color: white;
    height: 100%;
}

/* Chart.js */

.readme-chart {
    color: blue !important;
    font-size: 20px;
}

/* .timerange {
  list-style-type: none;
  width: 200px;
  display: flex;
  justify-content: space-between;
  padding-left:0;
}

.rangebtn:hover {
  color:#9fa2aa;
  font-weight: 500;
  cursor:pointer;
}

.rangebtn-light {
  color: #9fa2aa;
}

.rangebtn.active {
  color: #9fa2aa;
  font-weight: 500;
}
.error {
  padding: 5px;
  background: white;
  border: 1px solid;
  border-radius: 3px;
}
.errorMsg {
  color: red;
}

.simplechartwindow {
  margin-bottom:0px!important;
} */

/* .sell-fiat {
  cursor: pointer;
} */

/* Chart.js */

div.container-2yI8A9Vu {
    display: none !important;
}

div.centerElement-UEMVlR9F {
    display: none !important;
}

/* Add a right margin to each icon */
.fa {
    margin-left: -12px;
    margin-right: 8px;
}

.TVChartContainer {
    margin: 0 !important;
    height: 100%;
    border-radius: 7px !important;
    overflow: hidden;
    width: 100%;
}

@media only screen and (max-width: 600px) {
    .TVChartContainer {
        margin: 0 !important;
        margin-top: -12px !important;
        min-height: 340px;
        border-radius: 0px !important;
        margin-bottom: 20px !important;
        width: 100% !important;
    }
}

.button-restore {
    background: transparent;
    padding: 5px 10px;
    color: var(--text-base);
    border-radius: 5px;
    border: 1px solid white;
}

/* LeftSection.js */

.wlheading {
    color: white !important;
    opacity: 0.89;
}

.width-arrow {
    width: 10%;
}

.watchlistlogo {
    margin-top: -12px !important;
    margin-bottom: -10px !important;
}

.warn-msg {
    color: white;
    max-width: 100%;
    white-space: break-spaces !important;
}

.icon {
    display: none;
}

.icon-text {
    display: block;
}

/* Media query for devices */
@media only screen and (max-width: 576px) {
    #tokenprice {
        position: absolute;
        right: -50px;
    }

    #tokenmain {
        right: 0;
        margin-top: 5px;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: baseline;
    }
}

.graph {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    /* height: 100%; */
    /* height: 100% !important; */
    /* height:100%; */
}

@media only screen and (max-width: 576px) {
    .graph {
        width: 100% !important;
    }

    .header-res {
        display: flex !important;
        flex-direction: column !important;
    }

    .btn-res {
        width: 100%;
    }

    .row-res {
        margin-top: 10px;
        width: 100% !important;
    }

    .col-res {
        width: 50% !important;
    }

    .lp-dropdown {
        margin-left: 0px !important;
        min-width: 250px !important;
    }

    .width-20 {
        width: 20% !important;
    }

    .width-60 {
        width: 60% !important;
    }

    .width-62 {
        margin-top: 10px;
        width: 62% !important;
    }

    .tokendropdown {
        width: 90% !important;
    }

    .quick-tabs {
        justify-content: left !important;
    }

    .change-24 {
        margin-left: 5px !important;
    }

    .reddit-img {
        background-image: url('./assets/icons/reddit_white.svg');
        width: 25px;
    }

    .insta-img {
        background-image: url('./assets/icons/instagram_white.svg');
    }

    .web-img {
        background-image: url('./assets/icons/web_white.svg');
    }

    .twitter-img {
        background-image: url('./assets/icons/twitter_white.svg');
    }

    .contract-img {
        background-image: url('./assets/icons/contract_white.svg');
    }

    .telegram-img {
        background-image: url('./assets/icons/telegram_white.svg');
    }

    .transactions-img {
        background-image: url('./assets/icons/transactions_white.svg');
    }

    .holders-img {
        background-image: url('./assets/icons/holders_white.svg');
    }

    .icon {
        display: block;
        height: 16px;
        /* You must set a specified height */
        width: 16px;
        background-position: center;
        /* Center the image */
        background-repeat: no-repeat;
        /* Do not repeat the image */
        background-size: cover;
        /* Resize the background image to cover the entire container */
        text-indent: -9999px;
    }

    .icon-extra {
        background-color: #000000;
        /* Used if the image is unavailable */
        height: 16px;
        /* You must set a specified height */
        width: 16px;
        background-position: center;
        /* Center the image */
        background-repeat: no-repeat;
        /* Do not repeat the image */
        background-size: cover;
        /* Resize the background image to cover the entire container */
        text-indent: -9999px;
        margin-left: 5px;
    }

    .icon-text {
        display: none !important;
    }

    .badge {
        padding: 0.35em 0.45em !important;
        /* margin-bottom: 10px; */
    }

    .ml-small {
        margin-left: 0px !important;
    }

    .vote-scr {
        padding-top: 10px !important;
    }

    .nav-link-mobile {
        padding: 0.5rem 0.75rem !important;
    }

    .nav-item-mobile {
        margin-right: 0 !important;
    }
}

@media only screen and (max-width: 777px) {
    .rightside {
        margin-top: 0px;
    }
}

/* toggle css*/
.custom-control-label:after,
.custom-control-label:before {
    height: 19px !important;
}

.custom-switch .custom-control-label:after {
    width: 15px !important;
    height: 15px !important;
}

.custom-switch .custom-control-input:checked ~ .custom-control-label:after {
    left: calc(-3rem) !important;
}

/* toggle css*/

.nav-link.active {
    color: var(--text-primary) !important;
}

/* TokenAnalysis.js */

/* Styling */

/* .divider {
  border-bottom: 1px solid #292d40;
} */

.three-line-legend {
    width: 200px;
    height: 70px;
    position: absolute;
    padding: 8px;
    font-size: 12px;
    color: white;
    background-color: transparent;
    font-family: 'automate';
    text-align: left;
    z-index: 1000;
    pointer-events: none;
}

.p-0 {
    padding: 0;
}

.token-name {
    text-transform: capitalize;
    color: var(--text-primary) !important;
}

.import-btn {
    margin-top: 10px;
    margin-bottom: 10px;
    width: 130px;
    background-color: #171722 !important;
    color: #6f6e82 !important;
}

.import-btn:hover,
.import-btn:active {
    background-color: #12121a !important;
    color: #c3c2d4 !important;
}

.linkbadge {
    cursor: pointer;
}

.notfoundsearch {
    align-items: center !important;
    text-align: center !important;
    margin-bottom: 0px;
    font-size: 13px;
    /* margin-top: 7px; */
    color: #6f6e84;
}

.text-nodecor {
    text-decoration: none !important;
}

.fav-star {
    margin: 0px;
    float: left;
}

#onramper-widget {
    height: 550px;
    width: 100%;
    margin: 0;
}

.txnFilterCheckbox {
    display: flex;
    color: var(--text-primary) !important;
    vertical-align: center !important;
    margin-left: -12px !important;
    margin-top: -5px !important;
}

.txnFilterCheckbox.Mui-checked {
    color: var(--text-green) !important;
}

/* .txnFilterLabel {
  vertical-align: text-bottom;
} */

/* SetFonts */
.rubik {
    font-family: 'Ligurino';
}

/* Portfolio - Positions.js */

/* .table-dark .positionsTable {
    color: var(--text-primary) !important;
    font-size: 14px;
} */

.positions-header {
    font-size: 22px;
}

.table-dark thead th {
    background-color: var(--background-darker);
    color: var(--text-base) !important;
    font-size: 14px !important;
    font-weight: 400;
}

.table-dark td,
.table-dark th {
    /* padding: 0.5rem;
    vertical-align: top;
    font-size: 13px !important;
    font-weight: 300; */
    background-color: var(--background-base);
}

.table-dark thead th:first-child {
    border-radius: 20px 0px 0 0;
}

.table-dark thead th:last-child {
    border-radius: 0 20px 0 0;
}

.tradesButton:hover {
    background-color: #1f2230 !important;
}

/* Portfolio - BestWorstPerformers.js */
.table-bestworst {
    color: white;
    font-size: 14px;
    font-weight: 300;
}

/* FeaturedTokens.js */

.carouselToken {
    /* background-color: var(--background-light); */
    display: inline-block;
    border-radius: 7px;
    color: var(--text-base);
    padding: 15px 10px 15px 10px;
    margin-left: 6px;
    margin-right: 6px;
}

.featuredwrapper {
    max-width: 100%;
    overflow: hidden;
}

.marquee:hover {
    animation-play-state: paused;
}

.marquee {
    white-space: nowrap;
    height: 100%;
    overflow: hidden;
    margin-top: 5px;
    display: inline-block;
    animation: marquee 14s linear infinite;
}

.marquee p {
    display: contents;
    line-height: 0;
    font-size: 13px;
    font-weight: 300 !important;
    font-family: 'Ligurino';
}

@keyframes marquee {
    0% {
        transform: translate3d(0, 0, 0);
    }

    100% {
        transform: translate3d(-50%, 0, 0);
    }
}

/* misc */

@media (max-width: 767px) {
    .fit-for-mobile {
        margin-left: 0px !important;
    }
}

/* TradingWindow.js */

.alert-danger {
    background-color: rgba(248, 215, 218, 0.85);
    border: none;
}

button.btn-close:hover {
    color: #c3c2d4 !important;
}

.modal-dialog .modal-sm {
    border: none !important;
}
/* .trade-modal {
    top: 13%;
} */

/* @media (max-width: 767px) {
    .settings-modal {
        top: 5%;
        padding-left: 5%;
        padding-right: 5%;
    }
} */

.error {
    display: flex;
    justify-content: center;
    background: var(--background-lighter);
    border-radius: 6px;
    color: #ff5353;
    padding: 7px;
    border: 1px solid #ff5353;
}
.capitalize {
    text-transform: capitalize;
}
.cursor-pointer {
    cursor: pointer;
}

/* :root {
  --background-lightest: #454258;
  --background-lighter: #303044;
  --background-light: #232334;
  --background-base: blue;
  --background-dark: #171722;
  --background-darker: #12121a;
  --text-primary: #f7f7f7;
  --text-base: #c3c2d4;
  --text-muted: #6f6e82;
  --text-green:rgb(63, 182, 139);
  --text-red:rgb(255, 83, 83);
  --color-border-grey:#2d2d3c;
  --color-border-lighter:#393953;
  
} */

/* ProjectDescription.js */

.project-description {
    margin-bottom: 5px !important;
    font-size: 13px !important;
    font-weight: 100 !important;
    color: var(--text-base) !important;
}

.text {
    display: inline;
    width: 100%;
}

.read-or-hide {
    color: var(--color-teal);
    cursor: pointer;
    /* border: 1px solid var(--text-muted); */
    background-color: var(--background-light);
    margin-left: 5px;
    border-radius: 8px;
    padding-right: 5px;
    padding-left: 1px;
}

.update-info {
    color: var(--color-teal);
    cursor: pointer;
    border: 0px;
    background-color: var(--background-light);
    box-shadow: none;
    border-radius: 8px;
    padding-right: 5px;
    padding-left: 5px;
}

/* Spinner */
.spinner {
    position: absolute;
    left: 40%;
    top: 50%;
    height: 60px;
    width: 60px;
    margin: 0px auto;
    -webkit-animation: rotation 0.6s infinite linear;
    -moz-animation: rotation 0.6s infinite linear;
    -o-animation: rotation 0.6s infinite linear;
    animation: rotation 0.6s infinite linear;
    border-left: 6px solid rgba(0, 174, 239, 0.15);
    border-right: 6px solid rgba(0, 174, 239, 0.15);
    border-bottom: 6px solid rgba(0, 174, 239, 0.15);
    border-top: 6px solid rgba(0, 174, 239, 0.8);
    border-radius: 100%;
}

@-webkit-keyframes rotation {
    from {
        -webkit-transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(359deg);
    }
}
@-moz-keyframes rotation {
    from {
        -moz-transform: rotate(0deg);
    }
    to {
        -moz-transform: rotate(359deg);
    }
}
@-o-keyframes rotation {
    from {
        -o-transform: rotate(0deg);
    }
    to {
        -o-transform: rotate(359deg);
    }
}
@keyframes rotation {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(359deg);
    }
}
