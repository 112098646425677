.biginput-shadow {
    z-index: 99;
    .amount-in-out {
        color: var(--text-primary) !important;
    }
    @media only screen and (max-width: 700px) {
        background-color: var(--background-base) !important;
        border: none;
        border-radius: 0 !important;
    }
    .mobiletrading,
    .amount-in-out {
        @media only screen and (max-width: 679px) {
            background-color: var(--background-light) !important;
        }
    }
    .btn-token {
        @media only screen and (max-width: 679px) {
            background-color: var(--background-light) !important;
        }
    }
}


.btn-trade-unavailable {
    background-color: var(--background-light)!important;
    width: 100%;
    border-radius: 7px;
    color:var(--text-muted);
}