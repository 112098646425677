// Page layout

.discover-wrapper {
    height: calc(100vh);
    border-top: 1px solid var(--color-border-grey);
    margin-top: -3px;
}

// Title 

.discover-heading {
    font-weight: 100!important;
    font-family: 'Ligurino';
    color: var(--text-base)!important;
}

/*
** Trending.jsx
*/

.table-dark-standard {
 background-color: var(--background-dark);
 border-radius: 9px;
 color:var(--text-base);
 .dark-standard-thead {
    background-color: var(--background-darker);
 }
 tbody tr:hover td, .table-hover tbody tr:hover th {
    color:var(--text-primary);
}
&:last-child th, &:last-child td {
    border-bottom: none;
}
}

