[data-theme='dark'] {
    --background-lightest: #454258;
    --background-lighter: #303044;
    --background-light: #232334;
    --background-base: #1c1c27;
    --background-dark: #171722;
    --background-darker: #12121a;
    --background-darkest: #fffefc;
    --text-primary: #f7f7f7;
    --text-base: #c3c2d4;
    --text-muted: #6f6e84;
    --text-green: rgb(63, 182, 139);
    --text-blue: #5973fe;
    --text-red: rgb(255, 83, 83);
    --color-border-grey: #2d2d3c;
    --color-border-lighter: #393953;
    --color-orange: rgb(255, 182, 72);
    --color-teal: #508991;
}
[data-theme='light'] {
    --background-lightest: #d1cec7;
    --background-lighter: #dedbd5;
    --background-light: #ebe9e1;
    --background-base: #f7f4ed;
    --background-dark: #fffcf5;
    --background-darker: #faf7f7;
    --background-darkest: #fffefc;
    --text-primary: #002b36;
    --text-base: #073642;
    --text-muted: #586e75;
    --text-green: #2aa198;
    --text-lightgreen: rgb(63, 182, 139);
    --text-lightgreen2: rgba(63, 182, 139, 0.25);
    --text-red: #dc322f;
    --text-lightred: rgba(255, 83, 83, 0.2);
    --color-border-grey: #92a0a0;
    --color-border-lighter: #d1cec7;
}
