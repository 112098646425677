.auto-c-posi-rel {
    position: relative;
    border-radius: 8px !important;

    .auto-c-input {
        border: 10px solid rgba(0, 0, 0, 0.1);
        // background: url(../../assets/images/ic-down-arrow-sm.svg) no-repeat 96.5% center;
        background-size: 12px;
        background-color: #161c29;
        border: 1px solid #202230;
        color: #bfbfbf !important;
    }
    .auto-c-input:focus-within {
        background-color: #000;
    }

    .suggestions {
        border: none;
        list-style: none;
        max-height: 265px;
        overflow-y: auto;
        padding: 0;
        margin-top: 10px;
        position: absolute;
        left: 0;
        right: 0;
        top: 40px;
        border-radius: 8px !important;
        // background: var(--background-lighter)!important;
        background-color: #303044 !important;
        border: 1px solid #2d2d3c !important;
        backdrop-filter: blur(4px);
        z-index: 999;

        li {
            padding: 0.5rem;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            color: #000 !important;

            cursor: pointer;
            font-weight: 400;

            &:last-child {
                border-bottom: 0;
            }
        }
        .token-name {
            margin-bottom: -40px!important;
        }
    }

    .no-suggestions {
        position: static;
        left: 0;
        right: 0;
        top: 55px;
        box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1);
        border-radius: 4px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        background: var(--background-light);
        padding: 0.5rem;
        color: var(--text-base) !important;
        cursor: pointer;
        z-index: 1;
        em {
            font-style: normal;
        }
    }
}

.form-control:focus {
    box-shadow: none !important;
    color: var(--text-muted) !important;
    outline: none !important;
}

.ether-search-heading {
    // display: none;
    margin: 0;
    padding: 0;
}

.ether-search {
    text-decoration: none;
    color:#c3c2d4;
    margin-top: 0px;
    align-items: flex-start;
    border: none;
    // background-color: var(--background-lighter);
    background-color: #303044;
    &:hover {
        // background-color: var(--background-lightest) !important;
        background-color: #454258!important;
        color: var(--text-base) !important;
    }
}
