/******************
* Main Layout CSS *
******************/

.App {
    background-color: var(--background-base);
    transition: all 0.5s;
    overflow: hidden;
    height: 100vh !important;
    display: flex;
    position: relative !important;
    flex-direction: column;
}

.tokencontainer {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    height: 100%;
    background: gray;
    display: contents;
}

.middlelayout {
    width: auto;
    margin: 0;
    height: 100% !important;
}

.bg-darker-trade {
    // background-color: var(--background-dark);
    border-left: 1px solid var(--color-border-grey) !important;
    border-right: 1px solid var(--color-border-grey) !important;
    // margin-top: -8px !important;
    height: calc(100vh - 163px);
    min-height: 100% !important;
    max-height: 100% !important;
    display: content;
    overflow-y: scroll;
    position: relative !important;
    flex-direction: column;
    align-items: center;
}

.ccontainer {
    flex: auto;
    height: 100% !important;
    margin-bottom: 0 !important;
    border-top: 1px solid var(--color-border-grey);
    // padding-bottom: -4!important;
    // padding-bottom: 36px!important;
}

/***********************
* Custom button styles *
***********************/

// Sidebars

.infosidebar {
    height: calc(100vh - 443px) !important;
    // height: auto!important;
    // min-height: 100vh!important;
    // max-height: 100vh!important;
}

::-webkit-scrollbar {
    -webkit-appearance: none;
    appearance: none;
    width: 7px;
    height: 100%;
}
::-webkit-scrollbar-thumb {
    border-radius: 7px;

    background-color: var(--background-light);
    // -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

@media only screen and (max-width: 898px) {
    .App {
        background-color: var(--background-base);
        transition: all 0.5s;
        overflow-y: scroll;
        overflow-x: hidden;
        height: 100vh !important;
        /* min-height: 100%!important; */
        /* margin-bottom: -520px!important; */
        display: flex;
        flex-direction: column;
        /* height:auto!important; */
    }
}

// tabs panel

.collapsing {
    -webkit-transition: none;
    transition: none;
    display: none;
}

@media only screen and (max-width: 798px) {
    #tv_chart_container {
        border-bottom: none !important;
    }
}

#tv_chart_container {
    border-bottom: 1px solid var(--color-border-grey);
    // height:100px!important;
}

// style={{ marginLeft: '4px', marginRight: '4px', height: '100%' }}
@media only screen and (max-width: 798px) {
    .chartwrapper {
        margin-left: 0 !important;
        margin-right: 0px !important;
        height: 100% !important;
        margin-bottom: -12px !important;
    }
}

.chartwrapper {
    margin-top: 4px !important;
    margin-left: 4px;
    margin-right: 4px;
    height: 100% !important;
    // margin-bottom: -9px!important;
}

// Tooltip global

#tooltop-right .tooltip {
    // background-color: black;
}

div.tooltip-inner {
    background-color: black;
    font-size: 12px;
}
.tooltip-arrow::after {
    // display: none;
    color: blue !important;
}

// general styles
:focus {
    outline: 0 !important;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
}
.h6 {
    font-weight: 100;
    color: var(--text-primary) !important;
}
.list-group-dark {
    // border: none;

    .list-group-item {
        background-color: transparent;
        color: var(--text-base);
        border-left: none;
        border-right: none;
        border-radius: 0;
        border-top: none;
        border-bottom: var(--color-border-grey);
    }
}
/***********************
* Custom Button Styles *
***********************/

.btn-cryptoExpand {
    .expandIcon {
        color: var(--text-base) !important;
    }
    margin-top: -4px;
    margin-left: -3px;
    position: relative;
    height: 15px;
    width: 15px;
    border-radius: 100px;
    background-color: var(--background-lighter);
    &:hover {
        filter: brightness(125%);
        .expandIcon {
            color: var(--text-green) !important;
        }
    }
}

// button inside modal popups.. hardcoded bc theme files not working inside modal
.btn-slippage {
    background-color: #171722;
    border-radius: 7px;
    height: 28px !important;
    font-size: 11px;
    color: #6f6e84;
    &:hover {
        color: #c3c2d4 !important;
        filter: brightness(90%);
    }
}

.slippageinput {
    height: 28px !important;
    font-weight: 100;
    font-size: 11px;
    text-align: end;
    &:focus {
        color: #c3c2d4 !important;
    }
}

.btn-primary-modal {
    color: #2aa198 !important;
    background-color: rgba(46, 191, 148, 0.17) !important;
    border: none;
    font-weight: 100 !important;
    font-size: 15px !important;
    border-radius: 7px !important;
    &:hover,
    :focus {
        filter: brightness(130%);
    }
}

.btn-token {
    color: var(--text-base) !important;
    background: var(--background-lightest);
    border-radius: 8px !important;
    font-weight: 100;
    letter-spacing: 0.3px;

    &:hover {
        filter: brightness(115%);
    }
}

.btn-default-dark {
    color: var(--text-base) !important;
    background: var(--background-base) !important;
    // margin-top: -2px;
    height: 25px;
    width: 27px;
    border-radius: 7px !important;
    &:hover {
        background: var(--background-base) !important;
    }
    .refresh-button {
        transform: rotate(360deg);
        transition: transform 0.5s;
    }

    &:active .refresh-button {
        transform: rotate(0deg);
        transition: 0s;
    }
}

.btn-mobile-main {
    color: var(--text-base) !important;
    background: var(--background-lighter) !important;
    height: 37px;
    border-radius: 8px !important;
    &:hover {
        background: var(--background-lightest) !important;
    }
}

@media only screen and (min-width: 798px) {
    .btn-mobile-main {
        color: var(--text-base) !important;
        background: var(--background-lighter) !important;
        height: 35px !important;
        border-radius: 8px 0 0 8px !important;
        &:hover {
            background: var(--background-lightest) !important;
        }
    }
}

.btn-primary {
    color: var(--text-green) !important;
    width: 100%;
    border: none;
    font-weight: 100 !important;
    font-size: 15px;
    border-image-width: 2px;
    background-color: rgba(46, 191, 148, 0.2) !important;
    // filter: brightness(115%);
}

.btn-primary:hover,
.btn-primary:focus {
    filter: brightness(130%);
    box-shadow: none !important;
    outline: none !important;
}

.btn-primary-h {
    color: var(--text-green) !important;
    border: 1px solid var(--text-green) !important;
    cursor: pointer !important;
    width: 100%;
    font-weight: 100;
    font-size: 13px !important;
    background-color: rgba(46, 191, 148, 0.2) !important;
    border-radius: 7px;
    border: none;
    filter: brightness(115%);
}

.btn-primary-h:hover,
.btn-primary-h:focus {
    filter: brightness(130%);
    box-shadow: none !important;
    outline: none !important;
}

.btn-warning {
    color: #000 !important;
    width: 95%;
    font-weight: 700;
    font-family: 'automate', sans-serif;
    border: none;
    border-image-width: 2px;
}

// checkbox
.checkbox-dark {
    background: var(--background-base) !important;
    height: 28px;
    width: auto;
    border-radius: 7px !important;
    // padding-top: 6px !important;
    color: var(--text-muted);
    border-radius: 0px;
}

// crypto.js –– switches

label.form-check-label {
    color: var(--text-base) !important;
    font-weight: 400 !important;
    text-transform: lowercase !important;
}

input#spread-switch.form-check-input:checked {
    background-color: var(--color-blue) !important;
    border: none !important;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e") !important;
}

input#spread-switch.form-check-input {
    background-color: var(--background-darker) !important;
    border: none !important;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e") !important;
}

input#trade-switch.form-check-input {
    background-color: var(--background-darker);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e") !important;
    border: none;
}

input#single-switch.form-check-input {
    background-color: var(--background-darker);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e") !important;
    border: none;
}

// custom dropdown styles
.dropdown-primary {
    color: var(--text-base) !important;
    background: var(--background-dark) !important;
    border-radius: 8px !important;
}

.dropdown-secondary {
    border-radius: 8px !important;
}

// mobile nav

// #mob-co {
//     display: flex;
//     justify-content: center;
// }
.mobiletabs-wrapper {
position: relative;
width: 100%;
display: flex;
justify-content: center;
    .mobiletabs {
        font-size: 10px;
        position: relative;
        margin-top:10px;
        width: 300px;
    }
}

.mobiletabs .nav-link {
    color: #959cb2;
    cursor: pointer;
    border-radius: 12px!important;
    &.active {
        color: var(--text-primary) !important;
        background-color: var(--background-darker);
     
    }
}

.openMenu {
    border-radius: 8px;
    padding: 4px;
    margin-top: -6px;
    &:hover {
        background-color: #45425880 !important;
    }
}

.btn-alternative {
    background-color: #303044;
    color: #f7f7f7;
    &:hover {
        color: #f7f7f7;
        background-color: #454258;
    }
}

// header.js

.offcanvas-menu.active {
    opacity: 1;
    visibility: visible;
}

.offcanvas-menu.inactive {
    opacity: 0;
    visibility: hidden;
}

// crypto.js

.mobile-crypto-section-wrapper {
    padding-left: 15px;
    padding-right: 5px;
    padding-bottom: 15px;
    padding-top: 10px;
    -ms-overflow-style: none;  /* Removes scrollbars in Internet Explorer 10+ */
    scrollbar-width: none;  /* Removes scrollbars in Firefox */
    &::-webkit-scrollbar {
        display: none; /* Removes scrollbars in Safari and Chrome */
    }
}

.metrics-mobile-wrapper {
    display: flex;
    overflow-y: scroll;
    -ms-overflow-style: none;  /* Removes scrollbars in Internet Explorer 10+ */
    scrollbar-width: none;  /* Removes scrollbars in Firefox */
    &::-webkit-scrollbar {
        display: none; /* Removes scrollbars in Safari and Chrome */
    }
}

.metrics-card {
    background-color: var(--background-base);
    margin-left: -24px;
    box-shadow: none;
    height: auto;
    margin-right: 38px;
    width: 300px;
    min-width: 300px;
    border-radius: 9px;
    border: 1px solid var(--color-border-grey);
    // border:none;
    .metrics-title {
        color: var(--text-base);
        font-weight: 400;
        margin-left: -2px;
        font-size: 14px;
    }
    &:last-child {
        margin-right: -12px;
    }
}

.tokendetails-mobile .accordion-button {
    overflow: hidden;
    height: 22px !important;
    padding-left: 12px;
    padding-right: 12px;
    font-size: 13px;
    background-color: var(--background-base);
    color: #f7f7f7;
    &.collapsed {
        background-color: var(--background-base);
    }
    &.accordion-body {
        background-color: var(--background-base) !important;
    }
    &.accordion-item {
        background-color: var(--background-base) !important;
    }
}

.accordion-item {
    background-color: transparent !important;
}
.accordion-item:last-of-type .accordion-collapse {
    background-color: var(--background-base) !important;
    border-radius: 0 !important;
}

.accordion-button:after {
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23807E98'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
    background-color: var(--background-lighter);
    border-radius: 100px;
    padding: 0px !important;
}

.tokenrow {
    margin-top: -3px;
    // background-color: var(--color-border-grey);
    margin-bottom: 0px !important;
    // margin-right: 0.7%;
    // margin-left: 0.7%;
    padding-top: 0.6%;
}

@media only screen and (max-width: 798px) {
    .tokenrow {
        background-color: var(--background-base);
    }
}

// cryptooverview.js

.filterlabel {
    position: relative;
    top: -2px !important;
}

.accordion-button {
    color: #ffffff;
    background-color: var(--background-base);
    box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
    padding: 0;
    &:not(.collapsed) {
        background-color: var(--background-base);
    }
}

.accordion-collapse {
    margin-top: 0px !important;

    &:active {
        margin-top: 0px;
    }
    &:focus {
        margin-top: 0px;
    }
}

.accordion-collapse.collapse {
    margin-bottom: -9px !important;
}

.txtable {
    border-radius: 7px !important;
    box-shadow: inset 0px -200px 58px -60px rgba(23, 23, 34, 0.35);
    background-color: var(--background-base) !important;
    overflow-y: auto;
    margin-bottom: 0 !important;
    height: 260px;
    margin-bottom: 8px !important;
    border-bottom: 0px solid var(--color-border-grey);
    width: 100%;
    max-width: 20000px;
    @media only screen and (max-width: 798px) {
        height: 420px;
        margin-bottom: 0px;
        border: none;
        border-radius: 0 !important;
    }
    // &::-webkit-scrollbar {
    //     display: none !important;
    // }

    // @media only screen and (max-width: 798px) {
    //     .txtable {
    //         height: 160px!important;
    //     }
    // }
}

input#check-pair-filtering.form-check-input:checked {
    background-color: var(--text-bl) !important;
    border: none !important;
}

input#check-pair-filtering.form-check-input {
    background-color: var(--background-lightest) !important;
    border: none !important;
}

div.dropdown-menu.show {
    z-index: 9999;
    background-color: var(--background-light);
    border: var(--color-border-grey);
    box-shadow: none;

    & a.dropdown-item {
        color: var(--text-base);
    }
}

.dropdown-item {
    color: var(--text-base);
    background-color: var(--background-light) !important;
    &:hover {
        background-color: var(--background-light) !important;
    }
}

// boostrap colors

.bg-primary {
    background-color: var(--background-light) !important;
}

//

// tokeninfo.js
.twbg {
    background-image: url('./assets/img/dots-dots-dots.png') !important;
    object-fit: cover;
}

.mpbg {
    border-bottom: 1px solid var(--color-border-grey);
}

.vbg {
    border-bottom: 1px solid var(--color-border-grey);
}
/* 
    search
*/

.text-search {
    color: var(--text-base);
    font-weight: 100;
    text-decoration: none !important;
}

.text-searchh {
    color: var(--text-base) !important;
}

ul.suggestions {
    & li {
        // border-bottom: 1px solid var(--color-border-grey)!important;
        margin-bottom: 0px !important;
    }
}

/* 
    settings modal
*/

input#inputSlp.form-control {
    background-color: #171722 !important;
}

input#txTimeLimit.form-control {
    background-color: #171722 !important;
}

// toast

.Toastify__toast--default {
    background-color: #c3c2d4 !important;
    color: #232334 !important;
}

// SafetyCheck.js

.panel-info {
    margin-top: -8px;
    margin-right: 5px;
    color: var(--text-muted);
}

// .rightlayout { background:blue; height: 100%; width: 163px; float:right; }
// .leftlayout { height: 100%; width: 250px; background:red; float:left; }

// TwitterTimeline.js

.twitterbox-shadow {
    // box-shadow: inset 0px -66px 57px -50px rgba(0,0,0,0.2);
    box-shadow: inset 0px -200px 58px -60px rgba(23, 23, 34, 0.65);
}

.tweet-card {
    border-radius: 0 !important;
    // border-top: 1px solid var(--color-border-grey)!important;
    border-bottom: 1px solid var(--color-border-grey) !important;
    &:hover {
        background-color: var(--background-light) !important;
    }

    &:hover .tweet-body {
        color: var(--text-base);
    }
    &:hover .tweet-reply-to-user {
        color: var(--text-base);
    }
}

.tweet-author-logo {
    width: 38px;
    height: 38px;
    background-color: var(--background-lightest);
    border-radius: 100px;
}

.tweet-author-name {
    color: var(--text-base) !important;
    font-size: 15px;
}

.tweet-author-username {
    color: var(--text-muted) !important;
    font-size: 14px;
}

.tweet-body {
    color: var(--text-muted);
    font-size: 13px;
    font-weight: 300;
}

.tweet-reply-to-user {
    color: var(--text-muted);
    font-weight: 100;
    border: 1px solid var(--color-border-grey);
    border-radius: 12px;
    padding: 12px;
    font-size: 13px;
}

.tweet-reply-author-logo {
    width: 16px;
    height: 16px;
    background-color: var(--background-lightest);
    border-radius: 100px;
    margin-right: 5px;
}

.tweet-reply-author-name {
    color: var(--text-base) !important;
    font-weight: 500;
}

.tweet-reply-author-username {
    color: var(--text-muted) !important;
}

.tweet-attachment-poll {
    color: var(--text-base);
    &.twitter-poll-option {
        background-color: blue !important;
    }
}

.twitter-card-engagement {
    color: var(--text-muted) !important;
}

.tweet-attachment-photo {
    // max-height: 200px!important;
    // height:100%;
    // width:100%;
    // object-fit:cover!important;
    border-radius: 4px;
    display: block;
    max-width: 100%;
    max-height: 250px;
    width: 100%;
    height: auto;
}

// NewsWidget.js

.newsItem {
    // padding:2px;
    // border:1px solid var(--color-border-grey)!important;
    border-radius: 0px;
    &:hover {
        background-color: var(--background-light) !important;
    }
    &:hover .news-title {
        // text-decoration: underline;
        color: var(--text-base) !important;
    }
}

.news-subtitle {
    color: var(--text-base) !important;
    font-size: 13px;
    font-weight: 300;
    text-decoration: none !important;
}

.news-title {
    color: var(--text-muted) !important;
    font-size: 15px;
    font-weight: 400 !important;
}

// Pills

.minipill {
    cursor: pointer;
    font-size: 11px;
    background-color: var(--background-light) !important;
    border-radius: 10px !important;
    font-weight: 400;
    color: var(--text-base) !important;
    padding-left: 5px !important;
    padding-right: 5px !important;
    padding-top: 1px !important;
    padding-bottom: 0px !important;
    background-color: green;
    &.active {
        background-color: var(--background-lightest) !important;
        color: var(--text-primary) !important;
    }
}

.mediumpill {
    cursor: pointer;
    font-size: 12px;
    background-color: var(--background-light) !important;
    border-radius: 7px !important;
    font-weight: 400;
    color: var(--text-base) !important;
    padding-left: 7px !important;
    padding-right: 7px !important;
    padding-top: 3px !important;
    padding-bottom: 0px !important;
    margin-bottom: 1px;
    &.active {
        background-color: var(--background-dark) !important;
        color: var(--text-muted) !important;
    }
}

.largepill {
    cursor: pointer;
    font-size: 13px;
    background-color: var(--background-light) !important;
    border-radius: 7px !important;
    font-weight: 100;
    color: var(--text-base) !important;
    padding-left: 9px !important;
    padding-right: 9px !important;
    padding-top: 4px !important;
    padding-bottom: 2px !important;
    margin-bottom: 1px;
    &.active {
        background-color: var(--background-dark) !important;
        color: var(--text-muted) !important;
    }
}

// TokenDetails.js

.sidebar-listitem {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-top: 6px !important;
    padding-bottom: 5px !important;
    border-bottom: 1px solid var(--background-light) !important;
    &:last-child {
        border-bottom: 0px solid var(--background-light) !important;
    }
}

.deep-meep {
    width: 100%;
    height: 7px;
    background-color: var(--background-light);
    border-radius: 10px;
}

@media only screen and (max-width: 898px) {
    .tokendetails {
        padding-left: 12px !important;
        padding-right: 12px !important;
    }
    .tokendetailsrow {
        // max-height: 220px;
        overflow-y: hidden;
        overflow-x: hidden;
    }
}

.std-info {
    font-size: 13px !important;
    font-weight: 400 !important;
    color: var(--text-muted);
}

.std-info-modal {
    font-size: 13px !important;
    font-weight: 400 !important;
    color: #6f6e84 !important;
}

.modal-listitem:hover {
    color: #c3c2d4 !important;
}

.std-value {
    font-size: 13px !important;

    font-weight: 400 !important;
    color: var(--text-base);
}

// TokenSafetyScore.js

.indicator {
    position: relative;
    width: 156.8px;
    height: 78.4px;
    overflow: hidden;
}

.indicator::before,
.indicator::after {
    position: absolute;
}

.indicator::before {
    content: '';
    width: inherit;
    height: inherit;
    border: 12px solid var(--background-dark);
    border-bottom: none;
    border-top-left-radius: 122.5px;
    border-top-right-radius: 122.5px;
}

.indicator span.bar {
    position: absolute;
    top: 100%;
    left: 0;
    width: inherit;
    height: inherit;
    border: 12px solid;
    border-top: none;
    border-bottom-left-radius: 122.5px;
    border-bottom-right-radius: 122.5px;
    transform-origin: 50% 0;
    transform-style: preserve-3d;
    backface-visibility: hidden;
    animation-fill-mode: forwards;
    animation-duration: 0.4s;
    animation-timing-function: linear;
    z-index: 4;
    border-color: var(--text-green);
    animation-name: rotate-one;
}

.indicator span.result {
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    font-size: 1rem;
    font-weight: 500;
    color: var(--text-base);
}

span.result span:nth-child(1) {
    font-size: 2rem;
}

@keyframes rotate-one {
    0% {
        transform: rotate(0);
    }
}

// Utils CSS

a {
    color: var(--text-base);
    text-decoration: none;
}

.blue-hover {
    &:hover {
        color: var(--text-blue) !important;
        text-decoration: underline !important;
    }
}

.interactivehover {
    &:hover {
        filter: brightness(115%);
        transition-duration: 0ms !important;
    }
}

// navlink global

.nav-link.active {
    color: var(--text-primary) !important;
    &:hover {
        color: var(--text-primary) !important;
    }
}

.nav .nav-item {
    margin-left: 0 !important;
    margin-right: 0 !important;
}

// RecentlyViewed.js

// .list-group-item:hover {
//     filter:brightness(115%);
// }

// TokenLinks.js (mobile)
.bscbg {
    background-image: url('./assets/img/bscscan.svg') !important;
    background-repeat: no-repeat;
    background-size: 300px 100px;
}

/*
* Modals
*/

.modal {
    backdrop-filter: blur(5px) saturate(120%) !important;
    box-shadow: none !important;
    transition: none !important;
    // width: fit-content !important;
    // height: fit-content !important;
}

.tokensearch-modal {
    height: 348px !important;
}

div.tokensearch-modal.modal-body {
    color: #f7f7f7;
}

// Rightsidebar.js

.r-sidebar-div {
    height: 100%;
    border-radius: 7px;
    background-color: var(--background-base);
    margin: 4px;
    overflow: scroll;
}

/* fucking-center */

.fucking-center {
    display: flex;
    align-items: center !important;
    height: 100%;
    justify-content: center;
}

.subtlebigh3 {
    font-weight: 100;
    font-size: 18px;
    border-radius: 100px;
    color: var(--text-muted) !important;
    // background-color: var(--background-dark) !important;
}

/* WalletTransactions.js */

.no-transactions {
    position: absolute;
    width: calc(100% - 8px);
    height: calc(17%);
    font-size: 17px !important;
    font-weight: 100;
    display: flex;
    align-items: center;
    z-index: 0;
    justify-content: center;
}
