

.modal-content {
    background-color:#232334!important;
    border: none !important;
    border-radius: 7px!important;
  // padding: 7px;
  }

  .text-truncate {
    color:var(--text-muted);
    text-decoration: none!important;

  }

  
  .ti-search {
    padding-right:8px!important;
  }
  .inverse-button {
    cursor: pointer;
  }

  // .modal-body {
  //   width: 100%;
  //   height: 100%;
  //   padding: 0;
  //   margin-top: -70px;
  // }


  div.modal-title {
    color:#c3c2d4!important;
    font-weight: 500;
    font-size: 16px;

  }

  div.modal-header {
    border-bottom: none;
    border-radius: 7px 7px 0 0!important;
    height:48px;
    background-color: #303044;
    // padding-top:3%;
    // padding-bottom:2.5%;
  }

  div.modal-footer {
    border-radius: 0 0 7px 7px!important;
  }